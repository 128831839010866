import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { IVenue } from '../types/venue';
import { Venues } from '../components/pages/Venues';
import { Layout } from '../components/shared/Layout';
import { SEO } from '../components/shared/seo';

interface DataProps {
  venues: {
    edges: IVenue[];
  };
}

const VenuesPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  return (
    <Layout padded={true}>
      <SEO title="Locaties" />
      <Venues venues={data.venues.edges} />
    </Layout>
  );
};

export default VenuesPage;

export const query = graphql`
  query {
    venues: allMarkdownRemark(filter: { frontmatter: { post_type: { eq: "venue" } } }) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            lat
            lng
            street
            postal
            city
            website
            logo {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

import React from 'react';

import { IVenue } from '../../../types/venue';
import { EventMap } from '../../event/EventMap';

import styles from './venues.module.scss';

interface DataProps {
  venues: IVenue[];
}

export const Venues: React.FC<DataProps> = ({ venues }) => {
  const v = venues.map((item: any) => item.node);

  return (
    <div className={styles['venues-wrapper']}>
      <div className="container">
        <h1>Locaties</h1>
        <h2>Op deze plekken vind de Haagse Makers Week 2020 plaats</h2>
      </div>
      <EventMap venues={v} zoom={13} className={styles['venues-map']} />
    </div>
  );
};
